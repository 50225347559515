/* Resume.css */

body {
    font-family: Arial, sans-serif;
  }
 
  img.responsive{
height: auto;
  }
  .img {
    font-family: "Mate SC", serif;
    font-weight: 400;
    font-style: normal;
    width: 100%; /* or specific width */
    height: 100vh;
  }
  
  .css-8t49rw-MuiStepConnector-line {
    display: block;
    border-color: #bdbdbd;
    border-left-style: solid;
    border-left-width: 1px;
    min-height: 24px;
    margin-left: 9px;
  }
  .edu{
    text-decoration: underline;
    text-decoration-color: teal;
  }
  
  
  
  .li-skill {
    list-style-type: none;
  }
  
  .left {
    width: 20%;
    float: left;
  }
  
  .right {
    width: 80%;
    float: left;
  }
  
  .row {
    padding: 10px;
    margin-top: 1%;
    color: white;
  }
  
  .row::after {
    display: table;
    clear: both;
    content: "";
  }
  
  .main-bar {
    background-color: grey;
    display: block;
    height: 20px;
    text-align: center;
  }
  
  .main-bar span {
    background-color: teal;
    height: 20px;
    display: block;
    color: white;
  }
  
  .html {
    width: 100%;
    animation: html 2s;
  }
  
  .css {
    width: 95%;
    animation: css 2s;
  }
  
  .javascript {
    width: 70%;
    animation: javascript 2s;
  }
  
  .bootstrap {
    width: 80%;
    animation: bootstrap 2s;
  }
  
  .react {
    width: 70%;
    animation: react 2s;
  }
  
  .material {
    width: 60%;
    animation: material 2s;
  }
  
  .gitHub {
    width: 30%;
    animation: gitHub 2s;
  }
  
  .arabic {
    width: 100%;
    animation: arabic 2s;
  }
  
  .english {
    width: 80%;
    animation: english 2s;
  }
  
  .turkish {
    width: 50%;
    animation: turkish 2s;
  }
  
  /* Keyframes animations */
  
  @keyframes html {
    0% {
      width: 0%;
      background-color: rgb(6, 247, 247);
    }
    100% {
      width: 100%;
    }
  }
  
  @keyframes css {
    0% {
      width: 0%;
      background-color: rgb(6, 247, 247);
    }
    100% {
      width: 95%;
    }
  }
  
  @keyframes javascript {
    0% {
      width: 0%;
      background-color: rgb(6, 247, 247);
    }
    100% {
      width: 70%;
    }
  }
  
  @keyframes bootstrap {
    0% {
      width: 0%;
      background-color: rgb(6, 247, 247);
    }
    100% {
      width: 80%;
    }
  }
  
  @keyframes react {
    0% {
      width: 0%;
      background-color: rgb(6, 247, 247);
    }
    100% {
      width: 70%;
    }
  }
  
  @keyframes material {
    0% {
      width: 0%;
      background-color: rgb(6, 247, 247);
    }
    100% {
      width: 60%;
    }
  }
  
  @keyframes gitHub {
    0% {
      width: 0%;
      background-color: rgb(6, 247, 247);
    }
    100% {
      width: 30%;
    }
  }
  
  @keyframes arabic {
    0% {
      width: 0%;
      background-color: rgb(6, 247, 247);
    }
    100% {
      width: 100%;
    }
  }
  
  @keyframes english {
    0% {
      width: 0%;
      background-color: rgb(6, 247, 247);
    }
    100% {
      width: 80%;
    }
  }
  
  @keyframes turkish {
    0% {
      width: 0%;
      background-color: rgb(6, 247, 247);
    }
    100% {
      width: 50%;
    }
  }
  
  /* Media queries for responsiveness */
  
  @media (max-width: 1200px) {
    /* Adjustments for large screens */
    .left {
      width: 30%; /* Example: Decrease width */
    }
    .right {
      width: 70%; /* Example: Decrease width */
    }
  }
  
  @media (max-width: 768px) {
    /* Adjustments for tablets */
    .left,
    .right {
      width: 80%;
      margin-left: 8%;
      /* Example: Decrease width */
    }
    
  }
  
  @media (max-width: 600px) {
    /* Adjustments for smaller tablets */
    .left
    .right {
      width: 80%;
      /* Example: Decrease width */
    }
    
  }
  
  @media (max-width: 480px) {
    /* Adjustments for mobile phones */
    .left,
    .right {
      width: 100%; /* Full width on smaller screens */
      float: none; /* Remove float to stack elements vertically */
      margin-left: 0; /* Remove left margin */
      padding-left: 0; /* Remove left padding */
      text-align: center; /* Center align text */
    }
    .css-8t49rw-MuiStepConnector-line {
      margin-left: 13px;
    }

    @media (max-width: 480px) {
        /* Adjustments for mobile phones */
       .responsive {
        
          height: 4000px;
        }
    }
    @media (max-width: 480px) {
        /* Adjustments for mobile phones */
       .edu {
        
         text-align: 'center';
         margin-top: 3rem;
        margin-bottom: 2rem;
         font-size: 20px;
           line-height: 1;
         font-family: 'Patua One';
         color: white;
         text-decoration: underline;
         text-decoration-color: teal;
        }
    }

   
    .row {
      margin-top: 5%; /* Example: Adjust margins */
    }
  }
  
.main-3 {
  position: relative;
  width: 100%;
  height: 2100px;
}

.background-image3 {
  background-image: url('../Assets/workplace-with-computer-lamp-coffee-cup-table-dark-room.jpg');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.responsive {
  width: 100%;
  height: 2100px;
}
