@media (max-width: 480px) {
    /* Adjustments for mobile phones */
   .about {
    
      margin-left: 15%;
    }
}

.main-2 {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background-image2 {
  background-image: url('../Assets/workplace-with-computer-lamp-coffee-cup-table-dark-room.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .background-image2 {
    background-size: cover;
  }

  .img-2 {
    display: none;
  }
}
