.main-1 {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .background-image1 {
    background-image: url('../Assets/nikita-kachanovsky-OVbeSXRk_9E-unsplash.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  @media (max-width: 768px) {
    .background-image1 {
      background-size: cover;
    }
  
    .img-1 {
      display: none;
    }
  }
  