.main-6 {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .background-image6 {
    background-image: url('../Assets/edited.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    
  }
  
  @media (max-width: 768px) {
    .background-image6 {
      background-size: cover;
    }
  
    .img-6 {
      display: none;
    }
  }
  @keyframes fadeIn {
    0% { opacity: 0; transform: translateY(20px); }
    100% { opacity: 1; transform: translateY(0); }
  }
  
  .fade-in {
    animation: fadeIn 1s ease-in-out forwards;
  }
  
  .fade-in:nth-child(1) {
    animation-delay: 0.2s;
  }
  
  .fade-in:nth-child(2) {
    animation-delay: 0.4s;
  }
  
  .fade-in:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  .fade-in:nth-child(4) {
    animation-delay: 0.8s;
  }
  
  .fade-in:nth-child(5) {
    animation-delay: 1s;
  }
  
  .fade-in:nth-child(6) {
    animation-delay: 1.2s;
  }
  
  .main-6 {
    position: relative;
    overflow: hidden;
  }
  